import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from '../Components/Navbar';
import StepsBlock from '../Components/StepsBlock';
import FAQ from '../Components/Faq';
import Footer from '../Components/Footer';

import handleCustomEvent from '../Components/Helpers/CustomEventsHandler';
import { ThemeContext } from '../App';

export const Schedule = () => {
  const themeConfig = useContext(ThemeContext);
  const navigate = useNavigate();

  const handleQuestion = (event) => {
    const eventVal = event.currentTarget.getAttribute('data-id');
    if (eventVal === '1') {
      const eventData = {
        event: 'owns_charger'
      };
      handleCustomEvent(eventData);
      navigate('/schedule-calendar');
    } else if (eventVal === '2') {
      const eventData = {
        event: 'charger_not_arrived'
      };
      handleCustomEvent(eventData);
      navigate('/schedule-calendar');
    } else if (eventVal === '3') {
      const eventData = {
        event: 'does_not_own_charger'
      };
      handleCustomEvent(eventData);
      navigate('/schedule-no-charger');
    }
  };

  return (
    <div className="Schedule">
      <Helmet>
        <title>Helm Smart Energy | Schedule Your Free Consultation</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Navbar showlinks={themeConfig.scheduleContent.showNavLinks} />
      <div className="section-4 bg-schedule-header bg-cover bg-no-repeat mx-auto flex flex-col justify-center items-center mb-16 relative mt-[138px]">
        <div className="w-full h-full block bg-black bg-opacity-30 absolute"></div>
        <div className="z-10 w-full max-w-[360px] md:max-w-xl lg:max-w-[800px]">
          <h1 className="text-2xl md:text-3xl mt-6 md:mt-16 text-white font-bold text-center">
            {themeConfig.scheduleContent.pageHeading}
          </h1>
          <h2 className="font-semibold text-white text-center text-base md:text-xl mt-1">
            Installation starting at $1299
          </h2>
          <PreScheduleSection1 themeConfig={themeConfig} handleQuestion={handleQuestion} />
        </div>
      </div>
      <StepsBlock showlinks={false} showDescription={true} />
      <div className="faq-section bg-faq-bg bg-fixed bg-cover bg-no-repeat mx-auto hidden md:flex flex-col justify-center items-center">
        <div className="w-full bg-black bg-opacity-30 py-16">
          <FAQ mode="dark" />
        </div>
      </div>
      <div className="flex flex-col md:hidden">
        <FAQ />
      </div>
      <Footer showlinks={themeConfig.scheduleContent.showNavLinks} />
    </div>
  );
};

export default Schedule;

const PreScheduleSection1 = ({ themeConfig, handleQuestion }) => {
  return (
    <div className="flex flex-col lg:flex-row w-full mt-8 mb-16 lg:my-10 lg:mb-20 mx-auto bg-white">
      <div className="flex flex-col items-start justify-center p-8 lg:p-16 lg:px-40 w-full h-full">
        <h1 className="font-bold leading-[28px] text-2xl md:text-[24px] text-[#1C2839]">
          EV Charger Installation Assessment
        </h1>
        <div className="bg-[#008b4f] w-[200px] h-1 my-5"></div>
        <p className="text-sm text-[#272727] leading-6 mb-4 max-w-full font-medium">
          Welcome to your first step towards easy and efficient EV charging! Let's first make sure we can properly match
          your needs.
        </p>
        <div className="flex flex-col justify-center items-center w-full mt-2">
          <h1 className="font-semibold leading-[28px] md:leading-[36px] text-base md:text-[18px] text-[#1C2839]">
            Do you already own an EV Charger?
          </h1>
          <button
            className={`${themeConfig.bgColor} text-white w-full p-2 px-4 md:px-8 h-14 md:h-auto rounded-md text-sm my-2 font-semibold`}
            data-id={1}
            onClick={(e) => handleQuestion(e)}
          >
            I own an EV Charger
          </button>
          <button
            className={`${themeConfig.bgColor} text-white w-full p-2 px-4 md:px-8 h-14 md:h-auto rounded-md text-sm my-2 font-semibold`}
            data-id={2}
            onClick={(e) => handleQuestion(e)}
          >
            I ordered an EV Charger, but it hasn't arrived yet
          </button>
          <button
            className={`${themeConfig.bgColor} text-white w-full p-2 px-4 md:px-8 h-14 md:h-auto rounded-md text-sm my-2 font-semibold`}
            data-id={3}
            onClick={(e) => handleQuestion(e)}
          >
            I don't currently own an EV Charger
          </button>
        </div>
      </div>
    </div>
  );
};
