import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../Components/Navbar';
import { ThemeContext } from '../App';
import Footer from '../Components/Footer';

export const Terms = () => {
  const themeConfig = useContext(ThemeContext);

  return (
    <>
      <Helmet>
        <title>Helm Smart Energy | Terms and Conditions</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Navbar showlinks={true} />
      <div className="mt-44 mb-20 max-w-[340px] sm:max-w-xl lg:max-w-6xl mx-auto">
        <h1 className={`${themeConfig.textColor} text-xl font-bold`}>Helm Smart Energy Terms And Conditions</h1>
        <h2 className="font-bold my-5 text-lg">Date of Last Revision: October 16, 2023</h2>

        <p className="my-5 font-medium text-gray-600">
          These Terms and Conditions (“Terms”) apply to your access to and use of the Helm Smart Energy website (“Helm”)
          or use of any EV Charger products and services which can be purchased or accessed on Helm (“Services”).
          <b>
            Your use of this site constitutes your agreement to comply with these Terms. GreenMarbles, LLC
            (“GreenMarbles", “HSE”, “We “, “Our”, or “Us”) is sometimes referred to in these Terms as “HSE” as it owns
            the Helm Smart Energy program brand, but for the avoidance of doubt, HSE is solely a brand and not an
            entity. By accessing or using Helm, you agree to these Terms, including, where permitted by applicable law,
            the mandatory arbitration provision and class action waiver in Section 13. If you do not agree to these
            Terms, do not use Our Services.
          </b>
        </p>

        <p className="my-5 font-medium text-gray-600">
          We reserve the right to change or modify any of the terms and conditions contained in the Terms or any policy
          or guideline of Helm, at any time and in Our sole discretion. To the extent permitted by applicable law, any
          changes or modification will be effective immediately upon posting of the revisions on Helm, and you waive any
          right you may have to receive specific notice of such changes or modifications. Where required by law, We will
          provide you with a thirty (30) day notice using your email and/or your mailing address, or any other contact
          information We have for you in Our discretion, which notice shall set out the new clause, or the amended
          clause and how it read formerly, and the date on which the amendment will come into effect. You may refuse the
          amendment and rescind, or cancel your participation without cost, penalty or cancellation indemnity, by
          sending Us notice to that effect no later than thirty (30) days after the amendment comes into force, using
          the information in the notice. Your continued use of Helm following the posting of changes or modifications
          will confirm your acceptance of such changes or modifications. Therefore, you should frequently review the
          Terms and applicable policies from time-to-time to understand the terms and conditions that apply to your use
          of Helm. If you do not agree to the amended terms, you must stop using Helm.
        </p>

        <p className="my-5 font-medium text-gray-600">
          If you have any question regarding the use of Helm, please refer first to the Help Section or the Frequently
          Asked Questions. All other questions or comments about Helm or its contents should be directed to
          1-888-293-0328.
        </p>

        <h3 className="font-bold my-5 text-lg">1. Privacy Policy</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          Please refer to Our Privacy Policy at{' '}
          <a href="https://helmsmartenergy.com/privacy-policy" className={themeConfig.textColor}>
            https://helmsmartenergy.com/privacy-policy
          </a>
          {` `}
          for information on how We collect, use and disclose personally identifiable information from users.
        </p>

        <h3 className="font-bold my-5 text-lg">2. Copyright and Limited License</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          Unless otherwise indicated on Helm, Helm and all content and other materials contained therein, including,
          without limitation, the Helm Smart Energy logo, and all designs, text, graphics, pictures, information, data,
          software, sound files, other files and the selection and arrangement thereof (collectively, the "Materials")
          are the proprietary property of GreenMarbles or its licensors or users and are protected by U.S. and
          international copyright laws.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          You are granted a limited, revocable, non-sublicensable license to access and use Helm for your informational,
          non-commercial and personal use only. Such license is subject to these Terms and does not include: (a) any
          resale or commercial use of Helm or the Materials therein; (b) the distribution, public performance or public
          display of any Materials; (c) modifying or otherwise making any derivative uses of Helm or the Materials, or
          any portion thereof; (d) use of any data mining, robots or similar data gathering or extraction methods; (e)
          framing or using framing techniques to enclose any Helm content without Our express written consent; (f)
          taking any action that imposes an unreasonable or disproportionately large load on Helm or its network
          infrastructure or that adversely affects Helm and other users; (g) using any meta tags or any other “hidden
          text” that uses GreenMarbles or Helm Smart Energy's name or any of their trademarks or service marks, or (h)
          any use of Helm or the Materials other than for their intended purpose. Any use of Helm or the Materials other
          than as specifically authorized herein, without the prior written permission of one of Our company officers,
          is strictly prohibited and will terminate the license granted herein. Such unauthorized use may also violate
          applicable laws including without limitation copyright and trademark laws and applicable communications
          regulations and statutes. Unless explicitly stated herein, nothing in these Terms shall be construed as
          conferring any license to intellectual property rights, whether by estoppel, implication or otherwise. This
          license is revocable at any time.
        </p>

        <h3 className="font-bold my-5 text-lg">3. Trademarks</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          GreenMarbles, Helm Smart Energy, and their logos and any other GreenMarbles product or service names or
          slogans contained in Helm are trademarks of GreenMarbles, and may not be copied, imitated or used, in whole or
          in part, without prior written permission from Us or the applicable trademark holder. You may not use any
          metatags or any other "hidden text" utilizing "GreenMarbles", “Helm Smart Energy” or any other name, trademark
          or product or service name of GreenMarbles without Our prior written permission. In addition, the look and
          feel of Helm and any email and/or text message communications with you, including all page headers, text,
          custom graphics, images, button icons and scripts, is the service mark, trademark and/or trade dress of
          GreenMarbles and may not be copied, imitated or used, in whole or in part, without Our prior written
          permission. All other trademarks, registered trademarks, product names and company names or logos mentioned in
          Helm are the property of their respective owners. Reference to any products, services, processes or other
          information, by trade name, trademark, manufacturer, supplier, links or otherwise does not constitute or imply
          endorsement, sponsorship or recommendation thereof by Us (Linked sites are not under Our control, and the
          content available on such sites do not necessarily reflect Our opinion. We are providing these links as a
          convenience to you, and access to such sites is at your own risk. We assume no responsibility for the content
          of such linked sites.)
        </p>

        <h3 className="font-bold my-5 text-lg">4. Submissions</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          You acknowledge and agree that any materials, including but not limited to questions, comments, suggestions,
          ideas, plans, notes, drawings, original or creative materials or other information, regarding Helm,
          GreenMarbles or GreenMarbles' products or services that are provided by you in the form of email or other
          submissions to Us, or any postings on Helm, are non-confidential and shall become Our sole property. We shall
          own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted
          use and dissemination of these materials for any purpose, commercial or otherwise, without acknowledgment or
          compensation to you. Where such an assignment is not affected under applicable law, you provide Us with a
          non-exclusive, world-wide, perpetual, irrevocable, transferable, sub-licensable, royalty-free, and
          unrestricted license to copy, reproduce, adapt, transmit, edit, modify or otherwise use, publicly display,
          distribute, translate and create compilations and derivative works from such materials. Further, you hereby
          waive any moral rights you may hold in such materials in favor of Us.
        </p>

        <h3 className="font-bold my-5 text-lg">5. Registration Data; Account Security</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          In consideration of your use of Helm, if applicable, you agree to (a) provide accurate, current and complete
          information about you as may be prompted by any registration forms on Helm ("Registration Data"); (b) maintain
          the security of your password and other account credentials; (c) maintain and promptly update the Registration
          Data, and any other information you provide to Us, to keep it accurate, current and complete; and (d) accept
          all risks of unauthorized access to the Registration Data and any other information you provide to Us.
        </p>

        <h3 className="font-bold my-5 text-lg">6. Safety and Access Control; Lawful Use</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          It is your responsibility to exercise discretion and observe reasonable safety measures as may be necessary,
          or as may be required by law, to protect your personal safety and property, and to prevent unauthorized use of
          Helm and misuse of your passwords. You may only access and use Helm as authorized by these Terms and any other
          materials We provide to you. We are not liable to you for any unauthorized access or misuse of Helm. You agree
          that you will not use Helm to engage in any activities that are contrary to any applicable laws, rules, or
          regulations or that would violate the rights of, or cause harm to, any third party.
        </p>

        <h3 className="font-bold my-5 text-lg">7. Third-Party Components; Service Interruptions</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          You understand and agree that Helm or the Services may be interrupted or negatively affected by items outside
          of Our control and that the operation and functionality of the devices sold on Helm depends on the
          availability and proper operation of a variety of third party components that are outside of Our control,
          including without limitation, third-party devices or services, home automation systems, electrical power,
          radio frequency transmissions, integration platforms, and Internet access (the “Third-Party Components”). AS A
          RESULT, WE ARE NOT RESPONSIBLE OR LIABLE IN ANY MANNER FOR ANY THIRD-PARTY COMPONENTS OR FOR ANY FAILURE OF
          HELM OR SERVICES TO OPERATE AS INTENDED DUE TO ANY THIRD-PARTY COMPONENTS. We are also not responsible for
          interruptions of or problems with Helm or Services caused by acts of any governmental body, war, insurrection,
          sabotage, armed conflict, embargo, pandemic, fire, flood, strike or other labor disturbance, interruption of
          or delay in transportation, unavailability of or interruption or delay in telecommunications or third party
          services obtained by you or Us, virus attacks or hackers, failure of third-party software, any third-party
          negligence, willful misconduct, or misuse of Helm or Services.
        </p>

        <h3 className="font-bold my-5 text-lg">8. Disclaimers</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY US, AND TO THE FULLEST EXTENT PERMITTED BY
          APPLICABLE LAW, HELM, THE MATERIALS CONTAINED THEREIN AND THE SERVICES ARE PROVIDED ON AN "AS IS" BASIS
          WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WE DISCLAIM ALL OTHER WARRANTIES, EXPRESS OR
          IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE AND NON-INFRINGEMENT AS TO HELM AND THE SERVICES, INCLUDING THE INFORMATION, CONTENT AND
          MATERIALS CONTAINED THEREIN. WE DO NOT REPRESENT OR WARRANT THAT HELM OR THE SERVICES WILL BE UNINTERRUPTED OR
          ERROR-FREE. WE DO NOT REPRESENT OR WARRANT THAT HELM OR ITS SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL
          COMPONENTS. We reserve the right to change any and all content contained in Helm and to modify, suspend or
          discontinue Helm or any Services offered through Helm or any features or functionality of Helm or the Services
          at any time without notice and without obligation or liability to you. Reference to any products, services,
          processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not
          constitute or imply endorsement, sponsorship or recommendation thereof, or any affiliation therewith, by Us.
        </p>

        <h3 className="font-bold my-5 text-lg">9. Limitation of Liability</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (A) IN NO EVENT SHALL WE, OUR OFFICERS, DIRECTORS, MEMBERS,
          EMPLOYEES, REPRESENTATIVES OR AGENTS BE LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, INCLUDING
          BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT
          (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF
          OR INABILITY TO USE HELM, THE SERVICES, THE CONTENT OR THE MATERIALS CONTAINED IN OR ACCESSED THROUGH HELM,
          INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE BY USER ON ANY INFORMATION
          OBTAINED FROM US, OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS,
          DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING
          FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO OUR RECORDS, PROGRAMS
          OR SERVICES; AND (B) IN NO EVENT SHALL OUR AGGREGATE LIABILITY, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING
          NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING
          OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE HELM OR THE SERVICES OR TO THESE TERMS EXCEED THE GREATER
          OF $50 OR THE AMOUNT YOU HAVE PAID, IF ANY, TO US FOR ACCESS TO OR USE OF THE SERVICES.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          Some jurisdictions do not allow the exclusion of certain damages, so the above may not apply to you. If this
          section is held to be unenforceable, then liability will be limited to the fullest extent permitted by
          applicable law.
        </p>

        <h3 className="font-bold my-5 text-lg">10. Release</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          To the fullest extent permitted by applicable law, you release Us, Our officers, directors, members,
          employees, representatives, and agents from responsibility, liability, claims, demands and/or damages (actual
          and consequential) of every kind and nature, known and unknown (including, but not limited to, claims of
          negligence), arising out of or related to disputes between users and the acts or omissions of third parties.
          If you are a consumer who resides in California, you hereby waive your rights under California Civil Code §
          1542, which provides: “A general release does not extend to claims which the creditor does not know or suspect
          to exist in his or her favor at the time of executing the release, which if known by him or her must have
          materially affected his or her settlement with the debtor.”
        </p>

        <h3 className="font-bold my-5 text-lg">11. Transfer and Processing Data</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          In order for Us to provide the Services, you agree that We may process, transfer and store information about
          you in different jurisdictions in the United States, where you may not have the same rights and protections as
          you do under local law. As a result, this information may be subject to access requests from governments,
          courts, or law enforcement in the United States according to laws in such jurisdictions.
        </p>

        <h3 className="font-bold my-5 text-lg">12. SMS Text Messaging</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          We provide this SMS text messaging policy to explain how We use SMS text messaging. By completing the opt in
          and providing your mobile number, you expressly consent that We or Our service providers may send you periodic
          SMS or MMS messages (including messages made with an automatic dialing system) containing, but not limited to,
          important information, updates, deals, and specials. These messages can be recurring or one-time. Message and
          data rates may apply.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          Data obtained from you in connection with the SMS service may include your cell phone number, your carrier's
          name, and the date, time and content of your messages, as well as information that you provide. We may use
          this information to contact you and to provide the services you request from Us.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          By providing your mobile number and opting in, you agree you have ownership rights or permission to use the
          number given to Us.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          You may opt out of these communications at any time by following the procedure established by the text
          message. For example, replying “STOP” to any message you received. After this, you will no longer receive
          messages from that particular short code. If you want to join again, you can sign up as you did the first time
          and We or Our service providers will start sending messages to you again.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          You can receive assistance at any time by replaying “HELP” to any message you receive.
        </p>

        <h3 className="font-bold my-5 text-lg">13. Dispute Resolution; Binding Arbitration</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          This section applies to the fullest extent permitted by applicable law.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          Please read the following section carefully because it requires you to arbitrate certain disputes and claims
          with Us and limits the manner in which you can seek relief from Us, unless you opt out of arbitration by
          following the instructions set forth below. No class or representative actions or arbitrations are allowed
          under this arbitration agreement. In addition, arbitration precludes you from suing in court or having a jury
          trial.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          <u>No Representative Actions.</u> You and HSE agree that any dispute arising out of or related to these Terms
          or the Services is personal to you and HSE and that any dispute will be resolved solely through individual
          action, and will not be brought as a class arbitration, class action or any other type of representative
          proceeding.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          <u>Arbitration of Disputes.</u> Except for small claims disputes in which you or HSE seeks to bring an
          individual action in small claims court located in the county of your residence or disputes in which you or
          HSE seeks injunctive or other equitable relief for the alleged unlawful use of intellectual property, you and
          HSE waive any rights to a jury trial and to have any dispute arising out of or related to these Terms or the
          Services resolved in court. Instead, for any dispute or claim that you have against Us or relating in any way
          to the Services, you agree to first contact Us and attempt to resolve the claim informally by sending a
          written notice of your claim (“Notice”) to Us by email at support@helmsmartenergy.com or by certified mail
          addressed to GreenMarbles, LLC, Attn: Legal Department, 3419 Via Lido, Suite 388, Newport Beach, CA 92663. The
          Notice must (a) include your name, residence address, email address, and telephone number; (b) describe the
          nature and basis of the claim; and (c) set forth the specific relief sought. Our notice to you will be similar
          in form to that described above. If you and HSE cannot reach an agreement to resolve the claim within thirty
          (30) days after such Notice is received, then either party may submit the dispute to binding arbitration
          administered by JAMS or, under the limited circumstances set forth above, in court. All disputes submitted to
          JAMS will be resolved through confidential, binding arbitration. Arbitration proceedings will be held in
          Orange County, California, in accordance with the JAMS Streamlined Arbitration Rules and Procedures (“JAMS
          Rules”). The most recent version of the JAMS Rules is available on the JAMS website and are hereby
          incorporated by reference. You either acknowledge and agree that you have read and understand the JAMS Rules
          or waive your opportunity to read the JAMS Rules and waive any claim that the JAMS Rules are unfair or should
          not apply for any reason.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          You and HSE agree that these Terms affect interstate commerce and that the enforceability of this Section 13
          will be substantively and procedurally governed by the Federal Arbitration Act, 9 U.S.C. § 1, et seq. (the
          “FAA”), to the maximum extent permitted by applicable law. As limited by the FAA, these Terms and the JAMS
          Rules, the arbitrator will have exclusive authority to make all procedural and substantive decisions regarding
          any dispute and to grant any remedy that would otherwise be available in court, including the power to
          determine the question of arbitrability.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          The arbitrator may conduct only an individual arbitration and may not consolidate more than one individual's
          claims, preside over any type of class or representative proceeding or preside over any proceeding involving
          more than one individual. The arbitrator, HSE, and you will maintain the confidentiality of any arbitration
          proceedings, judgments and awards, including, but not limited to, all information gathered, prepared and
          presented for purposes of the arbitration or related to the dispute(s) therein. The arbitrator will have the
          authority to make appropriate rulings to safeguard confidentiality, unless the law provides to the contrary.
          The duty of confidentiality does not apply to the extent that disclosure is necessary to prepare for or
          conduct the arbitration hearing on the merits, in connection with a court application for a preliminary remedy
          or in connection with a judicial challenge to an arbitration award or its enforcement, or to the extent that
          disclosure is otherwise required by law or judicial decision.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          You and HSE agree that for any arbitration you initiate, you will pay the filing fee and We will pay the
          remaining JAMS fees and costs. For any arbitration initiated by Us, We will pay all JAMS fees and costs. You
          and HSE agree that the state or federal courts of the State of California and the United States sitting in
          Orange County, California have exclusive jurisdiction over any appeals and the enforcement of an arbitration
          award.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          Any claim arising out of or related to these Terms or the Services must be filed within one (1) year after
          such claim arose; otherwise, the claim is permanently barred, which means that you and HSE will not have the
          right to assert the claim.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          You have the right to opt out of binding arbitration within thirty (30) days of the date you first accepted
          the terms of this Section 13 by emailing Us at support@helmsmartenergy.com. In order to be effective, the
          opt-out notice must include your full name and address and clearly indicate your intent to opt out of binding
          arbitration. By opting out of binding arbitration, you are agreeing to resolve disputes in accordance with
          this Section 13.
        </p>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          If any portion of this Section 13 is found to be unenforceable or unlawful for any reason, (a) the
          unenforceable or unlawful provision shall be severed from these Terms; (b) severance of the unenforceable or
          unlawful provision shall have no impact whatsoever on the remainder of this Section 13 or the parties' ability
          to compel arbitration of any remaining claims on an individual basis pursuant to this Section 13; and (c) to
          the extent that any claims must therefore proceed on a class, collective, consolidated, or representative
          basis, such claims must be litigated in a civil court of competent jurisdiction and not in arbitration, and
          the parties agree that litigation of those claims shall be stayed pending the outcome of any individual claims
          in arbitration. Further, if any part of this Section 13 is found to prohibit an individual claim seeking
          public injunctive relief, that provision will have no effect to the extent such relief is allowed to be sought
          out of arbitration, and the remainder of this Section 13 will be enforceable.
        </p>

        <h3 className="font-bold my-5 text-lg">14. Applicable Law and Venue</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          This section applies to the fullest extent permitted by applicable law. These Terms and your use of Helm and
          the Services shall be governed by and construed in accordance with the laws of the State of California
          applicable to agreements made and to be entirely performed within the State of California, without resort to
          its conflict of law provisions. You agree that any action at law or in equity arising out of or relating to
          these Terms, Helm or the Services shall be filed only in the state and federal courts located in Orange
          County, California and you hereby irrevocably and unconditionally consent and submit to the exclusive
          jurisdiction of such courts over any suit, action or proceeding arising out of these Terms, Helm or the
          Services.
        </p>

        <h3 className="font-bold my-5 text-lg">15. Termination</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          Notwithstanding any of these Terms, We reserve the right, without notice and in Our sole discretion, to
          terminate your license to use Helm or the Services, and to block or prevent future your access to and use of
          Helm or the Services.
        </p>

        <h3 className="font-bold my-5 text-lg">16. Severability</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          If any part, term or provision of this Agreement is held to be illegal, in conflict with any law or otherwise
          invalid, the remaining portion or portions shall be considered severable and not be affected by such
          determination, and the rights and obligations of the parties shall be construed and enforced as if the Terms
          did not contain the particular part, term or provisions held to be illegal or invalid.
        </p>

        <h3 className="font-bold my-5 text-lg">17. Indemnification</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          You agree to defend, indemnify and hold Us harmless from and against any and all claims, liabilities, demands,
          penalties, forfeitures, suits, judgments and the associated costs and expenses (including attorney's fees),
          which We may hereafter incur, become responsible for or payout arising out of your use of Helm or the Services
          or your breach of any term or provision of these Terms
        </p>

        <h3 className="font-bold my-5 text-lg">18. Waiver; Entire Agreement; Assignment</h3>
        <p className="my-5 font-medium text-gray-600 pl-5 md:pl-10">
          No waiver of any of these Terms shall be deemed a further or continuing waiver of such term or any other term.
          Except as expressly provided elsewhere on Helm, these Terms constitute the entire agreement between you and
          HSE with respect to your use of Helm. You may not assign these Terms to any other party.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
