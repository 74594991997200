import React, { useContext } from 'react';
import { ThemeContext } from '../App';

export const Header = ({ title, subtitle, ctaLink, ctaText, bgClass }) => {
  const themeConfig = useContext(ThemeContext);

  return (
    <div className={`${bgClass} bg-no-repeat bg-center bg-cover h-[540px] bg-gray-700 flex items-center mt-[138px]`}>
      <div className="absolute w-full h-[540px] bg-black opacity-60"></div>
      <div className="relative z-10 mx-auto px-10 md:px-24 w-full max-w-[1440px] gap-y-4 flex flex-col justify-start">
        <h1 className="text-3xl md:text-4xl font-bold text-white max-w-[540px]">{title}</h1>
        <p className="text-lg font-medium leading-6 text-white mb-3 max-w-[540px]">{subtitle}</p>
        <a href={ctaLink}>
          <button className={`${themeConfig.bgColor} px-8 py-3 max-w-xs rounded-md font-bold text-white`}>
            {ctaText}
          </button>
        </a>
        <h2 className="font-semibold text-white">Installation starting at $1299</h2>
      </div>
    </div>
  );
};

export default Header;
