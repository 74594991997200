import React, { useContext } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ThemeContext } from '../App';

export const Carousel = ({ heading, showCta }) => {
  const themeConfig = useContext(ThemeContext);

  const testimonials = [
    {
      id: 1,
      avatar: `t1.webp`,
      name: `Kevin Kinslow`,
      location: `Falls Church, VA`,
      feedback: `Just had my charger installed with John with Helm Smart Energy. Couldn't be happier with the experience. John was professional all the way from the beginning.`
    },
    {
      id: 2,
      avatar: `t2.webp`,
      name: `Leigh Reid`,
      location: `Naples, FL`,
      feedback: `Each company should be happy to have employees such as Ernesto. Thank you for hiring him and providing an environment that fosters great customer care.`
    },
    {
      id: 3,
      avatar: `t3.webp`,
      name: `David Martinez`,
      location: `Sacramento, CA`,
      feedback: `I gotta say, Helm Smart Energy made this process smooth. Justin from their remote consultation team was incredibly helpful and answered all my questions. Everything went like clockwork after that. These guys really know their stuff.`
    },
    {
      id: 4,
      avatar: `t4.webp`,
      name: `Laura Richardson`,
      location: `Seattle, WA`,
      feedback: `Had my remote consultation with Miguel from Helm Smart Energy and it was such a breeze. He patiently explained everything and helped me understand what to expect. Definitely made the whole process less daunting. Really thankful for their top-notch service!`
    },
    {
      id: 5,
      avatar: `t5.webp`,
      name: `Katherine Shanta`,
      location: `Austin, Tx`,
      feedback: `I recently had my EV charger installed by Helm Smart Energy, and I must say the process was impressively smooth. They exhibited great professionalism and were very patient in explaining every step of the process. Highly recommend them.`
    }
  ];

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="bg-[#e6e6e6] mx-3 p-3 h-[50px] w-[50px] shadow-md active:shadow-none rounded-full cursor-pointer hidden md:flex items-center justify-center"
        onClick={onClick}
      >
        <i className="fa fa-caret-right text-3xl ml-1 text-gray-500" />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="bg-[#e6e6e6] mx-3 p-3 h-[50px] w-[50px] shadow-md active:shadow-none rounded-full cursor-pointer hidden md:flex items-center justify-center"
        onClick={onClick}
      >
        <i className="fa fa-caret-left text-3xl mr-1 text-gray-500" />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      }
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <div className="section-1 max-w-[1440px] mx-auto flex flex-col justify-center items-center my-20">
      <h1 className="text-2xl md:text-3xl max-w-[300px] lg:max-w-2xl text-center text-[#585858] font-bold">
        {heading}
      </h1>
      <div className="flex items-center w-full md:w-auto">
        <div className="max-w-[360px] md:max-w-[540px] lg:max-w-[1000px] xl:max-w-[1200px] w-full mx-auto">
          <Slider {...settings}>
            {testimonials.map((item) => {
              return (
                <div
                  className="bg-[#f3f3f3] text-center py-8 mb-3 h-[390px] md:h-[520px] xl:h-[480px] w-full lg:border-[14px] border-white"
                  key={item.id}
                >
                  <img
                    src={`${process.env.REACT_APP_IMGIX_URL}/helm/marketing-site/${item.avatar}`}
                    className="w-[100px] md:w-[160px] mx-auto rounded-full"
                    alt="customer thumbnails"
                    width="160"
                    height="285"
                  />
                  <h1 className="text-[26px] text-[#363636] font-medium">{item.name}</h1>
                  <p className="text-lg text-[#727272] font-medium">{item.location}</p>
                  <p className="text-sm md:text-base font-medium text-[#4e4e4e] text-center mx-auto leading-5 mt-3 max-w-[300px] md:max-w-[300px] lg:max-w-[320px] xl:max-w-[420px]">
                    {item.feedback}
                  </p>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>

      <a href={themeConfig.carouselContent.ctaLink}>
        <button
          className={`${themeConfig.bgColor} px-8 py-3 mt-6 md:mt-0 max-w-xs rounded-md font-bold text-white ${
            showCta ? 'flex' : 'hidden'
          }`}
        >
          {themeConfig.carouselContent.ctaText}
        </button>
      </a>
    </div>
  );
};

export default Carousel;
