import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../App';

export const Navbar = ({ showlinks }) => {
  const themeConfig = useContext(ThemeContext);
  const [activeNavItem, setActiveNavItem] = useState('');

  // open navpanel
  const openMobileNav = () => {
    document.getElementsByClassName('mobile-nav')[0].style.transform = 'translateX(0)';
  };

  // close navpanel
  const closeMobileNav = () => {
    document.getElementsByClassName('mobile-nav')[0].style.transform = 'translateX(-100%)';
  };

  useEffect(() => {
    if (window.location.pathname === '/how-it-works') setActiveNavItem('how-it-works');
    else if (window.location.pathname === '/about') setActiveNavItem('about');
  }, []);

  return (
    <div className="navbar mx-auto w-full bg-white h-[138px] fixed z-20 top-0 shadow-md">
      <div className="topnav mx-auto w-full h-[50px] bg-[#3c3c3c]">
        <div className="mx-auto max-w-[1440px] w-full h-[50px] px-5 md:px-10 text-white text-base flex items-center">
          {showlinks && (
            <div className="flex lg:hidden" onClick={() => openMobileNav()}>
              <i className="fa fa-bars text-2xl"></i>
            </div>
          )}
          <div className="flex flex-col md:flex-row items-end md:items-center gap-x-5 ml-auto">
            {process.env.REACT_APP_EMAIL !== '' && (
              <>
                <a href={`mailto:${process.env.REACT_APP_EMAIL}`} className="text-sm font-medium">
                  {process.env.REACT_APP_EMAIL}
                </a>
                <span className="hidden lg:flex">|</span>
              </>
            )}
            <a href={`tel:${process.env.REACT_APP_PHONE}`} className="text-sm font-medium">
              {process.env.REACT_APP_PHONE}
            </a>
          </div>
        </div>
      </div>
      <div className="main-nav mx-auto max-w-[1440px] w-full h-[88px] md:px-10 text-base flex items-center">
        {themeConfig.logo !== '' && (
          <>
            <a href="/">
              <img
                src={`${themeConfig.logo}?w=280`}
                width={140}
                height={249}
                className={`w-[140px] ml-3 lg:ml-0`}
                alt="brand logo"
              />
            </a>
            <a href="/" className="ml-auto md:ml-4">
              <img
                src={`https://greenmarbles-client-sites.imgix.net/best-buy/bbsp_logo1.webp`}
                width={160}
                height={249}
                className={`w-[160px] ml-0 md:ml-4`}
                alt="brand logo"
              />
            </a>
          </>
        )}
        {showlinks && (
          <ul className="hidden lg:flex items-center justify-center gap-8 ml-auto">
            <li
              className={`${themeConfig.textHoverColor} font-semibold cursor-pointer flex items-center ${
                activeNavItem === 'about' ? `${themeConfig.textColor}` : 'text-gray-500'
              }`}
            >
              <a href="/about">About Us</a>
            </li>
            <li
              className={`${themeConfig.textHoverColor} font-semibold cursor-pointer flex items-center ${
                activeNavItem === 'how-it-works' ? `${themeConfig.textColor}` : 'text-gray-500'
              }`}
            >
              <a href="/how-it-works">How It Works</a>
            </li>
            <li>
              <a href={themeConfig.navContent.ctaLink}>
                <button className={`${themeConfig.bgColor} px-8 py-3 rounded-md font-bold text-white`}>
                  {themeConfig.navContent.ctaText}
                </button>
              </a>
            </li>
          </ul>
        )}
      </div>

      {/* Mobile Nav */}
      <div className="mobile-nav flex flex-col bg-white w-full h-full fixed -translate-x-full transition all duration-200 left-0 top-0 z-20">
        <div className="h-[50px] w-full flex items-center bg-[#3c3c3c]">
          <div className="ml-6" onClick={() => closeMobileNav()}>
            <i className="fa fa-times text-2xl text-white"></i>
          </div>
        </div>
        {showlinks && (
          <ul className="flex flex-col">
            <li className="px-6 py-5 font-medium">
              <a href="/how-it-works">How It Works</a>
            </li>
            <li className="px-6 py-5 font-medium border-t border-b border-gray-300">
              <a href="/about">About Us</a>
            </li>
            <li className="mx-auto mt-6">
              <a href={themeConfig.navContent.ctaLink}>
                <button className={`${themeConfig.bgColor} px-8 py-3 mt-2 rounded-md font-bold text-white`}>
                  {themeConfig.navContent.ctaText}
                </button>
              </a>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Navbar;
