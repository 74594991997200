import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../Components/Navbar';
import StepsBlock from '../Components/StepsBlock';
import FAQ from '../Components/Faq';
import Footer from '../Components/Footer';

import { ThemeContext } from '../App';

export const ScheduleNoCharger = () => {
  const themeConfig = useContext(ThemeContext);

  return (
    <div className="Schedule">
      <Helmet>
        <title>Helm Smart Energy | Schedule Your Free Consultation | No Charger</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Navbar showlinks={themeConfig.scheduleContent.showNavLinks} />
      <div className="section-4 bg-schedule-header bg-cover bg-no-repeat mx-auto flex flex-col justify-center items-center mb-16 relative mt-[138px]">
        <div className="w-full h-full block bg-black bg-opacity-30 absolute"></div>
        <div className="z-10 w-full max-w-[360px] md:max-w-xl lg:max-w-[800px]">
          <h1 className="text-2xl md:text-3xl mt-6 md:mt-16 text-white font-bold text-center">
            {themeConfig.scheduleContent.pageHeading}
          </h1>
          <h2 className="font-semibold text-white text-center text-base md:text-xl mt-1">
            Installation starting at $1299
          </h2>

          <PreScheduleSection2 themeConfig={themeConfig} />
        </div>
      </div>
      <StepsBlock showlinks={false} showDescription={true} />
      <div className="faq-section bg-faq-bg bg-fixed bg-cover bg-no-repeat mx-auto hidden md:flex flex-col justify-center items-center">
        <div className="w-full bg-black bg-opacity-30 py-16">
          <FAQ mode="dark" />
        </div>
      </div>
      <div className="flex flex-col md:hidden">
        <FAQ />
      </div>
      <Footer showlinks={themeConfig.scheduleContent.showNavLinks} />
    </div>
  );
};

export default ScheduleNoCharger;

const PreScheduleSection2 = ({ themeConfig }) => {
  return (
    <div className="flex flex-col lg:flex-row w-full mt-8 mb-16 lg:my-10 lg:mb-20 mx-auto bg-white">
      <div className="flex flex-col items-start justify-center p-8 lg:p-16 lg:px-36 w-full h-full">
        <h1 className="font-bold leading-[28px] text-2xl md:text-[24px] text-[#1C2839]">
          Preparing for Your EV Charging Future
        </h1>
        <div className="bg-[#008b4f] w-[200px] h-1 my-5"></div>
        <p className="text-sm text-[#272727] leading-6 mb-4 max-w-full font-medium">
          No charger? No problem! <br /> <br /> Once you've selected your preferred EV charger, come back to schedule
          your installation consultation. If you have any questions or need advice on choosing a charger or about the
          installation process, please call us. We're here to help you get ready for a seamless charging experience.
        </p>
        <div className="flex flex-col justify-center items-center w-full mt-2">
          <a
            href="https://www.bestbuy.com/site/garage/electric-car-chargers/pcmcat265100050011.c?id=pcmcat265100050011"
            className="w-full"
          >
            <button
              className={`${themeConfig.bgColor} text-white w-full p-2 px-4 md:px-8 h-14 md:h-auto rounded-md text-sm my-2 font-semibold`}
            >
              Explore EV Chargers At Best Buy
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
