import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Home from './Pages/Home';
import Schedule from './Pages/Schedule';
import HowItWorks from './Pages/HowItWorks';
import About from './Pages/About';
import Terms from './Pages/Terms';
import PrivacyPolicy from './Pages/PrivacyPolicy';

import themeWrapper from '../src/Components/Themes/themeWrapper';
import handleCustomEvent from './Components/Helpers/CustomEventsHandler';
import ScheduleCalendar from './Pages/ScheduleCalendar';
import ScheduleNoCharger from './Pages/ScheduleNoCharger';

export const ThemeContext = React.createContext();

export const App = () => {
  let themeConfig = themeWrapper().helmTheme;

  // Inititalize GA4
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  // Fire custom GA4 event on clicking Schedule Your Consultation button
  useEffect(() => {
    document.querySelector('body').addEventListener('click', (e) => {
      const el = e.target.innerHTML.toLowerCase();
      if (el === 'schedule your consultation' || el === 'get started') {
        const event = {
          name: 'schedule_button_click'
        };
        handleCustomEvent(event);
      }
    });
  });

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <Home />
          </ThemeContext.Provider>
        }
      />
      <Route
        path="/how-it-works"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <HowItWorks />
          </ThemeContext.Provider>
        }
      />
      <Route
        path="/about"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <About />
          </ThemeContext.Provider>
        }
      />
      <Route
        path="/schedule"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <Schedule />
          </ThemeContext.Provider>
        }
      />
      <Route
        path="/schedule-calendar"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <ScheduleCalendar />
          </ThemeContext.Provider>
        }
      />
      <Route
        path="/schedule-no-charger"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <ScheduleNoCharger />
          </ThemeContext.Provider>
        }
      />
      <Route
        path="/terms-conditions"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <Terms />
          </ThemeContext.Provider>
        }
      />
      <Route
        path="/terms-and-conditions"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <Terms />
          </ThemeContext.Provider>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <PrivacyPolicy />
          </ThemeContext.Provider>
        }
      />
    </Routes>
  );
};

export default App;
