import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../Components/Navbar';
import { ThemeContext } from '../App';
import Footer from '../Components/Footer';

export const PrivacyPolicy = () => {
  const themeConfig = useContext(ThemeContext);

  return (
    <>
      <Helmet>
        <title>Helm Smart Energy | Privacy Policy</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Navbar showlinks={true} />
      <div className="py-6 mt-[160px] mb-14 mx-auto max-w-5xl">
        <h3 className={`mb-4 font-bold text-3xl ${themeConfig.textColor}`}>Privacy Policy</h3>
        <h3 className="mb-10 font-bold text-lg">Date of Last Revision: October 16, 2023</h3>
        <p className="mb-6 font-medium text-gray-600">
          This Privacy Policy explains how information about you is collected, used and disclosed by GreenMarbles, LLC
          and its subsidiaries and affiliated companies ("GreenMarbles", “HSE”, “We”, “Our”, or “Us”) when you access or
          otherwise use the Helm Smart Energy website (“Helm”) or use any EV Charger products and services which can be
          purchased or accessed on Helm (collectively “Services”).{' '}
          <b>
            GreenMarbles is sometimes referred to in these Terms as “HSE” as it owns the Helm Smart Energy program
            brand, but for the avoidance of doubt, HSE is solely a brand and not an entity.
          </b>
        </p>
        <p className="mb-6 font-medium text-gray-600">
          We may change this Privacy Policy from time to time. If We make changes, We will notify you by revising the
          date at the top of the policy and, in some cases, We may provide you with additional notice (such as adding a
          statement to Our homepage or sending you an email notification), and such modifications shall be effective
          upon posting by Us on Helm. Your continued use of Helm or the Services after We post a revised Privacy Policy
          signifies your acceptance of the revised Privacy Policy. Therefore, We encourage you to regularly review the
          Privacy Policy whenever you access Helm to stay informed about Our information practices and the ways you can
          help protect your privacy.
        </p>
        <h3 className="mb-2 mt-8 font-bold text-2xl">Collection of Information</h3>
        <h3 className="mb-6 font-bold text-xl">
          Categories and Types of Information We Collect That You Provide to Us or Our Service Providers
        </h3>
        <p className="mb-6 font-medium text-gray-600">
          We collect information you provide directly to Us, such as when you create an account, connect or configure
          your EV Charger, request customer support, or otherwise communicate with Us. The categories and types of
          information We may collect include your personal information such as name, date of birth, credit card
          information, home or business address, phone number, and email address; geolocation data such as location
          where products are installed; commercial information such as home automation system, devices (purchased or
          considered), and account information (such as model and product registration numbers); Internet or other
          network information around your interaction with Helm; inferences drawn from personal information; and other
          information We request or that you choose to provide.
        </p>
        <p className="mb-6 font-medium text-gray-600">
          When you use the Services, you may provide certain additional information to Our service providers (e.g., home
          or business size, home or business system configuration, login or access credentials, sensor names, devices,
          account information, schedule, mode, automation settings, or device settings) in order for Us to coordinate
          any installation or to personalize your Services. We may also receive information that you provide to Our
          partners, including emergency contact information and user profile information, enabling Helm to coordinate
          provision of the Services.
        </p>

        <h3 className="mb-2 mt-8 font-bold text-2xl">Information About Your Use of Helm and the Services</h3>
        <h3 className="mb-6 font-bold text-xl">Usage Information</h3>
        <p className="mb-6 font-medium text-gray-600">
          We collect information about your use of Helm and the Services, including information about when, where, and
          how you use Helm and the Services. Some of this information you directly provide to Us and some is collected
          automatically when you use Helm and the Services.
        </p>
        <p className="mb-6 font-medium text-gray-600">
          Information regarding your use of the Services may also be collected by Our service providers, including but
          not limited to: performance of the EV Charger, electrical usage or consumption, and usage and alert logs. This
          type of information is received from your EV Charger and your computer or other electronic device through
          which you may access, browse, receive, or otherwise use the Services (“Personal Devices”).
        </p>

        <h3 className="mb-6 font-bold text-xl">Automatically Collected Information</h3>
        <p className="mb-6 font-medium text-gray-600">
          When you access or use Helm, We automatically collect information about you, including:
        </p>
        <ul className="list-disc mt-6 pl-4 font-medium text-gray-600">
          <li className="mb-4">
            Personal Device Information: We collect information about the Personal Device you use to access Helm or the
            Services, including usage information and statistics, an IP address, Personal Device type and model, browser
            type, operating system and version, Personal Device ID, mobile network information, domain names, access
            times, referring website addresses, Personal Device settings and history, and other information collected
            about your access of Helm or the Services or receipt of communications or notifications.
          </li>
          <li className="mb-4">
            Services: We collect information about your devices, including account information, network information,
            make and model, product registration numbers, device name, type, features, and state (on or off), usage,
            device groups, routines and routine executions.
          </li>
          <li className="mb-4">
            Information Collected by Cookies and Other Tracking Technologies: Like most online services and mobile
            applications, We may use cookies and other technologies, such as web beacons, web storage, and unique
            advertising identifiers, to collect information about your activity, browser, and device. For more
            information about cookies, and how to disable them, please see "Your Choices" below.
          </li>
        </ul>

        <h3 className="mb-6 font-bold text-xl">Use of Information</h3>
        <p className="mb-6 font-medium text-gray-600">
          We may use information about you for various purposes, including to:
        </p>
        <ul className="list-disc mt-6 pl-4 font-medium text-gray-600">
          <li className="mb-1">Enroll you as a customer and manage your account</li>
          <li className="mb-1">Determining your eligibility for certain products or services</li>
          <li className="mb-1">Providing access to your account electronically</li>
          <li className="mb-1">Verifying your identity and maintaining customer security</li>
          <li className="mb-1">
            Provide and deliver products and services you request from Us and Our vendors, process transactions, and
            send you related information, including confirmations and invoices
          </li>
          <li className="mb-1">Provide customer service and improving your customer experience</li>
          <li className="mb-1">Billing your account and processing payment</li>
          <li className="mb-1">
            Send you technical notices, updates, security alerts, and support and administrative messages
          </li>
          <li className="mb-1">
            Send newsletters and otherwise communicate with you, including via email, about products, services, offers,
            promotions, rewards, and events offered by Us and others, and provide news and information We think will be
            of interest to you
          </li>
          <li className="mb-1">Process and deliver contest entries and rewards</li>
          <li className="mb-1">
            Notify you about updates or changes that may affect your services or access to your website or applications
          </li>
          <li className="mb-1">Monitor and analyze trends, usage, and activities in connection with Helm</li>
          <li className="mb-1">Conduct market research and customer surveys</li>
          <li className="mb-1">Create customer prospect lists</li>
          <li className="mb-1">
            Provide analytic services and/or enable certain vendors to provide similar services on Our behalf
          </li>
          <li className="mb-1">Maintain security and detect and prosecute security incidents</li>
          <li className="mb-1">
            Detect, investigate, and prevent fraudulent transactions and other illegal activities and protect the rights
            and property of HLSHS and others
          </li>
          <li className="mb-1">
            Maintain, personalize and improve Helm and provide advertisements, content or features that match user
            profiles or interests
          </li>
          <li className="mb-1">Manage, maintain and develop plans for Our products and services</li>
          <li className="mb-1">Support Our business functions</li>
          <li className="mb-1">Improve products and services</li>
          <li className="mb-1">
            Undertake internal testing, research and analysis for technological and product development
          </li>
          <li className="mb-1">Manage vendor relationships</li>
          <li className="mb-1">
            Evaluate business transactions where personal information is among the assets transferred
          </li>
          <li className="mb-1">
            Link or combine with information We get from others to help understand your needs and provide you with
            better service
          </li>
          <li className="mb-1">Cooperate with regulatory authorities and state tax collection authorities</li>
          <li className="mb-1">Respond to law enforcement requests</li>
          <li className="mb-1">
            Comply with an applicable law, court order, commission request, or request from other state or federal
            governmental agencies with legal authority to obtain such personal information assisting emergency
            responders in situations of immediate threat to life or property
          </li>
          <li className="mb-1">
            Carry out any contractual obligations and enforce rights between you and Us, including for billing and
            collections
          </li>
          <li className="mb-1">Carry out any other purpose which is permitted or required by applicable law; and</li>
          <li className="mb-1">Carry out any other purpose for which the information was collected.</li>
        </ul>
        <p className="mb-6 font-medium text-gray-600">
          We are based in the United States and We process and store information in the U.S. and, as applicable, other
          countries. By accessing or using Helm or otherwise providing information to Us, you consent to the processing
          and transfer of information in and to the U.S. and if applicable, other non-Canadian and non-European
          countries. As such, We and Our service providers may transfer your information to, or store or access it in,
          jurisdictions that may not provide equivalent levels of data protection as your home jurisdiction. As a
          result, this information may be subject to access requests from governments, courts, or law enforcement in the
          United States or those jurisdictions according to the laws in such jurisdictions.
        </p>

        <h3 className="mb-6 font-bold text-xl">Sharing of Information</h3>
        <p className="mb-6 font-medium text-gray-600">
          We may share information about you as follows or as otherwise described in this Privacy Policy:
        </p>
        <ul className="list-disc mt-6 pl-4 font-medium text-gray-600">
          <li className="mb-1">
            With affiliates, vendors, consultants and other service providers (such as Internet service providers,
            social media networks, data analytics providers, and joint marketing partners) who need access to such
            information to carry out work on Our behalf;
          </li>
          <li className="mb-1">
            Non-affiliated vendors or agencies, such as subcontractors providing requested goods and services, payment
            processors, customer survey administrators, and central stations We select to receive alarm signals and
            coordinate emergency response;
          </li>
          <li className="mb-1">
            In response to a request for information if We believe disclosure is in accordance with, or required by, any
            applicable law or legal process, including lawful requests by public authorities to meet national security
            or law enforcement requirements;
          </li>
          <li className="mb-1">
            If We believe your actions are inconsistent with the spirit or language of Our user agreements or policies,
            or to protect the rights, property and safety of HLSHS or others;
          </li>
          <li className="mb-1">
            In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition
            of all or a portion of Our business to another company;
          </li>
          <li className="mb-1">
            Between and among Us and Our current and future parents, affiliates, subsidiaries and other companies under
            common control and ownership;{' '}
          </li>
          <li className="mb-1">
            With your consent or at your direction, including if We notify you through Helm that the information you
            provide will be shared in a particular manner and you provide such information; and
          </li>
          <li className="mb-1">With regulators and governmental authorities.</li>
        </ul>
        <p className="my-6 font-medium text-gray-600">
          We may also share aggregated or anonymized information that does not directly identify you.
        </p>

        <h3 className="mb-6 font-bold text-xl">Helm Smart Energy Program</h3>
        <p className="mb-6 font-medium text-gray-600">
          When you purchase an EV Charger, We (or one of Our subcontractors) perform the following activities: 1)
          Enrollment, 2) Fulfillment and installation, 3) Billing, and 4) Customer service and call center capabilities.
        </p>

        <h3 className="my-6 font-bold text-xl">Analytics Services Provided by Others</h3>
        <p className="mb-6 font-medium text-gray-600">
          We may allow third parties to provide analytics services. These entities may use cookies, web beacons and
          other technologies to collect information about your use of Helm and other websites, including your IP
          address, web browser, pages viewed, time spent on pages, screen recordings, links clicked and conversion
          information. This information may be used by HLSHS and others to, among other things, analyze and track data,
          determine the popularity of certain content, and deliver content targeted to your interests on Helm and other
          websites and better understand your online activity.
        </p>
        <p className="mb-6 font-medium text-gray-600">
          Specifically, some of Our sites may use Google Analytics to analyze traffic or Mouseflow to capture screen
          recordings. You can find out more information about Google Analytics cookies and Mouseflow here:
        </p>
        <ol className="list-decimal mt-6 pl-6 font-medium text-gray-600">
          <li className="mb-1">
            <a
              href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
              className={`underline ${themeConfig.textColor}`}
            >
              https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
            </a>
            ;
          </li>
          <li className="mb-1">
            <a
              href="https://mouseflow.com/legal/company/privacy-policy/"
              className={`underline ${themeConfig.textColor}`}
            >
              https://mouseflow.com/legal/company/privacy-policy/
            </a>
            .
          </li>
        </ol>
        <p className="mb-6 font-medium text-gray-600">
          To opt-out of Google Analytics relating to your use of Our sites, you can download and install the Browser
          Plugin available via this link:{' '}
          <a href="https://tools.google.com/dlpage/gaoptout?hl=en" className={`underline ${themeConfig.textColor}`}>
            https://tools.google.com/dlpage/gaoptout?hl=en
          </a>
          .
        </p>

        <h3 className="mb-6 font-bold text-xl">Security</h3>
        <p className="mb-6 font-medium text-gray-600">
          We take reasonable measures to help protect information about you from loss, theft, misuse and unauthorized
          access, disclosure, alteration and destruction. Personal information may be accessed by persons within Our
          organization, or third-party service providers, who require such access to carry out the purposes indicated
          above, or such other purposes as may be permitted or required by the applicable law. We manage the personal
          information We collect from Our offices in Newport Beach, California.
        </p>
        <p className="mb-6 font-medium text-gray-600">
          Although We have taken measures to help protect Our information about you, no security measure can provide
          absolute protection. Therefore, We cannot ensure or warrant the security of any information you provide to Us.
        </p>

        <h3 className="my-6 font-bold text-xl">Links, Services and Off-Line Information</h3>
        <p className="mb-6 font-medium text-gray-600">
          We offer products and services from other companies and Helm contains links to websites not operated by Us. We
          are not responsible for the privacy practices of third parties. If you purchase such products and services
          and/or visit these third-party sites, you should check their applicable privacy policies to determine how they
          treat user information. We strive to protect your information off-line as well. We restrict access to
          information to those employees who need to process your account such as a customer service representative or
          financial personnel.
        </p>

        <h3 className="mb-2 mt-8 font-bold text-2xl">Your Choices</h3>
        <h3 className="mb-6 font-bold text-xl">Account Information</h3>
        <p className="mb-6 font-medium text-gray-600">
          You may update, correct or delete information about you or limit Our processing of data about you at any time
          by emailing Us at{' '}
          <a href="mailto:support@helmsmartenergy.com" className={themeConfig.textColor}>
            support@helmsmartenergy.com
          </a>
          . Note that We may retain certain information as required by law or for legitimate business purposes, and
          there may be some cases in which We cannot provide access to certain information. We may require you to verify
          your identity before We provide you with access to your personal information.
        </p>

        <h3 className="my-6 font-bold text-xl">Local Storage, Cookies and Web Beacons</h3>
        <p className="mb-6 font-medium text-gray-600">
          Most web browsers are set to accept cookies by default. In many cases, if you prefer, you can disable certain
          cookies through your web browser's privacy settings. Cookies are required for the proper functioning of Helm
          and applications. Please note that if you choose to remove or reject cookies, this could affect the
          availability and functionality of Helm and applications.
        </p>
        <p className="mb-6 font-medium text-gray-600">
          Some web browsers can also transmit “Do Not Track” signals that indicate that a user does not wish to have
          activity tracked. A Do Not Track (DNT) standard has not yet been adopted, and therefore Helm does not honor
          DNT signals at this time. We also allow some third parties to place cookies on Our websites. When you visit
          Our websites, third parties may collect personal information about your online activities over time and across
          different websites or online services, including cookies and beacons.
        </p>
        <p className="mb-6 font-medium text-gray-600">
          We may utilize local storage, cookies, and web beacons to better target Our services. Data placed in “Local
          Storage” is per domain and persists after the browser is closed. “Cookies” are small identifiers sent from a
          web server that are stored on your computer's hard drive for the purpose of identifying your browser during
          interaction on Helm. “Web beacons” are invisible electronic images, called a single-pixel (1x1) or clear GIF,
          which can recognize certain types of information on your computer, such as a cookie number, time and date of a
          page view, and a description of the page where the web beacon is placed. All of these tools can assist Us in
          collecting data related to your use of Helm, tracking customer trends and patterns, seeing which areas and
          features of Helm are popular and counting visits, and help Us address technical issues. These tools do not
          contain any personal data, they do not damage your files, and they do not allow Us or anyone else to access
          your personally identifiable information. These tools may contain such information as an IP address, DNS host
          name, and/or browser type. Cookies can also identify you on future visits to Helm, making navigation easier
          and allowing you to bypass the process of re-entering certain information each time you access Helm.
        </p>
        <p className="mb-6 font-medium text-gray-600">
          If you wish to prevent web beacons, most web browsers have a help option that will explain how to reject them.
        </p>

        <h3 className="my-6 font-bold text-xl">Promotional Communications</h3>
        <h3 className="mb-3 font-bold text-lg">Mail</h3>
        <p className="mb-6 font-medium text-gray-600">
          You may receive marketing solicitations via postal mail from Us/Helm, or Our joint marketing partners or other
          authorized third parties. To opt out of such communications, please contact Us using the email address in the
          “Contact Us” section.
        </p>

        <h3 className="mb-3 font-bold text-lg">Email</h3>
        <p className="mb-6 font-medium text-gray-600">
          You may receive email communications from Us in connection with your use of Our products and services, such as
          when you sign up for the products and services. For specific information regarding changing email
          communication preferences associated with your account, you may either follow the instructions in those emails
          or contact the email address in the “Contact Us” section. If you opt out, We may still send you
          non-promotional communications, such as those about your account or Our ongoing business relations.
        </p>
        <p className="mb-6 font-medium text-gray-600">
          In some cases, We may contact you to let you know about new products or services that may be of interest to
          you. These marketing emails will include an “unsubscribe” instruction (usually found at the bottom of the
          email) that you may use to opt out of receiving future marketing-related emails.
        </p>

        <h3 className="mb-3 font-bold text-lg">Social Media Communications</h3>
        <p className="mb-6 font-medium text-gray-600">
          We use social media sites such as Facebook and Twitter to communicate with you about Our programs and
          services. You can follow Us on these social media sites to receive regular updates. These sites also allow Us
          to present specific advertisements to you based on the personal information that both We and the social media
          sites maintain about you. We may share your personal information with social media sites for the purpose of
          providing you with customized ads. In some states, this may be considered a “sale” unless the social media
          site is acting as Our “service provider” as that term is defined by law.
        </p>

        <h3 className="mb-3 font-bold text-lg">Mobile Push Notifications/Alerts</h3>
        <p className="mb-6 font-medium text-gray-600">
          With your consent, We may send promotional and non-promotional push notifications or alerts to your Personal
          Device. You can deactivate these messages at any time by changing the notification settings on your Personal
          Device.
        </p>

        <h3 className="mb-3 font-bold text-lg">SMS Text Messaging</h3>
        <p className="mb-6 font-medium text-gray-600">
          With your consent, We or Our service providers may send you periodic SMS or MMS messages (including messages
          made with an automatic dialing system) containing, but not limited to, important information, updates, deals,
          and specials. These messages can be recurring or one-time. Message and data rates may apply. You may opt out
          of these communications at any time by following the procedure established by the text message. For example,
          replying “STOP” to any message you received. After this, you will no longer receive messages from that
          particular short code. If you want to join again, you can sign up as you did the first time and We or Our
          service providers will start sending messages to you again. You can receive assistance at any time by
          replaying “HELP” to any message you receive.
        </p>
        <p className="mb-6 font-medium text-gray-600">
          Data obtained from you in connection with the SMS service may include your cell phone number, your carrier's
          name, and the date, time and content of your messages, as well as information that you provide.
        </p>

        <h3 className="my-6 font-bold text-xl">State-Specific Requirements</h3>
        <h3 className="mb-3 font-bold text-lg">“Do Not Track” Disclosures Required by California and Delaware</h3>
        <p className="mb-6 font-medium text-gray-600">
          California and Delaware law require Us to indicate whether We honor “Do Not Track” settings in your browser
          concerning targeted advertising. We may use how you browse and shop in order to show you ads for Us or Our
          advertising partners that are relevant to your interests. We may use cookies and other information to provide
          interest-based ads to you which are based on your browsing behavior and are more tailored to your interests.
          These interest-based ads may be presented to you while you are browsing Our site or third-party sites not
          owned by Us.
        </p>
        <p className="mb-6 font-medium text-gray-600">
          We may participate in ad networks that may use your interest in Helm and your browser activity on Helm to show
          you interest-based advertisements on participating websites. Helm does not respond to DNT signals or similar
          mechanisms to indicate you do not wish to be tracked or receive interest-based ads.
        </p>
        <h3 className="mb-3 font-bold text-lg">Nevada</h3>
        <p className="mb-6 font-medium text-gray-600">
          If you are a Nevada resident, you have the right to request that We not sell your personal information. To
          submit this request, please send Us an email at{' '}
          <a href="mailto:support@helmsmartenergy.com" className={themeConfig.textColor}>
            support@helmsmartenergy.com
          </a>
          . However, please note that We do not sell personal information for monetary consideration.
        </p>
        <h3 className="mb-3 font-bold text-lg">
          Your California Privacy Rights under the California Consumer Privacy Act (CCPA)
        </h3>
        <p className="mb-6 font-medium text-gray-600">
          If you are a California resident, as set forth below, you have specific rights with respect to your personal
          information that is collected by companies.
        </p>

        <div className="px-8">
          <h3 className="mb-3 font-bold text-lg">Sharing and Sale of Personal Information</h3>
          <p className="mb-6 font-medium text-gray-600">
            We share personal information for legitimate business purposes. We are not a data broker and do not share
            personal information with data brokers, which are businesses that knowingly collect and sell to third
            parties the personal information of an individual with whom the business does not have a direct
            relationship. We do not sell personal information as the term is traditionally understood (i.e., We do not
            exchange personal information directly for monetary consideration).
          </p>
          <p className="mb-6 font-medium text-gray-600">
            However, certain activities such as allowing third-party cookies on Our websites, sharing information for
            purposes of Internet-based advertising, and/or maintaining certain relationships through which We work to
            bring you non-affiliate products may be considered a "sale" under the CCPA. To opt-out of the sale of your
            personal information, please see “Do Not Sell or Share My Personal Info” section below.
          </p>
          <p className="mb-6 font-medium text-gray-600">
            We disclose and/or sell (under the definitions in the CCPA) the following categories of personal
            information: commercial Information, Internet or other network activity, geolocation data, inferences drawn
            from personal information, and other types of personal information.
          </p>
          <p className="mb-6 font-medium text-gray-600">
            We disclose for a business purpose or sell the above categories of personal information to: Our subsidiaries
            and affiliates; internet service providers and social media networks; data analytics providers; joint
            marketing partners who perform activities at Our direction; affiliates and vendors that interact with Us in
            connection with the goods We offer and the services We perform; non-affiliated vendors or agencies, such as
            subcontractors providing requested goods and services, payment processors, and customer survey
            administrators; third parties to whom you or your agents authorize Us to disclose your personal information;
            and regulators and governmental authorities.
          </p>
          <p className="mb-6 font-medium text-gray-600">
            We do not knowingly sell the personal information of consumers under the age of 16.
          </p>
          <h3 className="mb-3 font-bold text-lg">
            {' '}
            Your Right to Know, Delete, Opt-Out (of selling or sharing), Correct and Limit
          </h3>
          <p className="mb-6 font-medium text-gray-600">
            If you are a California resident, to exercise your right to know, delete, opt-out (of selling or sharing),
            correct or limit (details are below), please submit a consumer request to Us by:
          </p>
          <ul className="list-disc mt-6 mb-6 pl-4 font-medium text-gray-600">
            <li className="mb-1">
              Calling Toll-Free:{' '}
              <a href="tel:1-888-293-0328" className={themeConfig.textColor}>
                1-888-293-0328
              </a>{' '}
              or
            </li>
            <li className="mb-1">
              Sending an email with your request to{' '}
              <a href="mailto:support@helmsmartenergy.com" className={themeConfig.textColor}>
                support@helmsmartenergy.com
              </a>
            </li>
          </ul>
          <p className="mb-6 font-medium text-gray-600">
            We are required by law to verify your identity before processing your request. Upon receipt of your request,
            We will contact you with the specific steps needed to verify your identity. Only you or your authorized
            agent acting on your behalf may make a verifiable consumer request regarding your personal information. A
            verifiable consumer request must:
          </p>
          <ul className="list-disc mt-6 mb-6 pl-4 font-medium text-gray-600">
            <li className="mb-1">
              Provide enough information so that We may verify your identity as the person about whom We collected
              personal information.
            </li>
            <li className="mb-1">
              Describe your request with sufficient detail such that We may properly understand, process, and respond.
            </li>
          </ul>
          <p className="mb-6 font-medium text-gray-600">
            While an account with Us is not necessary to submit a verifiable consumer request, if you maintain a
            password-protected account with Us, We may verify your identity via existing authentication practices. We
            may require that you provide Us with additional personal information, including your name, email address,
            phone number, billing or service address, or customer account number for the purpose of verifying your
            identity and processing your request.
          </p>
          <ol className="list-decimal mt-6 pl-6 font-medium text-gray-600">
            <li className="mb-4">
              <b>Right to Know:</b> California residents have the right to request a record of their personal
              information We collect, use, disclose or sell.
            </li>
            <li className="mb-4">
              <b>Right to Delete:</b> California residents have the right to request We delete certain personal
              information We maintain about them.{' '}
              <i>
                Please note that We must maintain personal information to provide Our products and services; and to meet
                legal and regulatory requirements. Such required personal information is not eligible to be deleted.
              </i>
            </li>
            <li className="mb-4">
              <b>Right to Opt-Out of the Sale or Sharing of Your Information -</b>
              <div className="mt-6 px-6">
                <h3 className="mb-3 font-bold text-sm">
                  <b>"DO NOT SELL OR SHARE MY PERSONAL INFO"</b>
                </h3>
                <p className="mb-6 font-medium text-gray-600">
                  You have the right to opt out of the sale of your personal information. "Sell," "selling," "sale," or
                  "sold" means selling, renting, releasing, disclosing, disseminating, making available, transferring,
                  or otherwise communicating orally, in writing, or by electronic or other means, a consumer's personal
                  information by the business to another business or a third party for monetary or other valuable
                  consideration.
                </p>
                <p className="mb-6 font-medium text-gray-600">
                  You also have the right to opt out of the sharing of your personal information. “Sharing”, as defined
                  by California law, means sharing, renting, releasing, disclosing, disseminating, making available,
                  transferring, or otherwise communicating orally, in writing, or by electronic or other means, a
                  consumer's personal information to a third party for cross-context behavioral advertising, whether or
                  not for monetary or other valuable consideration, including transactions between a business and a
                  third party for cross-context behavioral advertising for the benefit of a business in which no money
                  is exchanged.
                </p>
              </div>
              <li className="mb-4">
                <b>Right to Correct:</b> California residents have the right to request that We correct any inaccurate
                personal information that We may have about them.
              </li>
              <li className="mb-4">
                <b>Right to Limit:</b> California residents have the right to limit the use and disclosure of sensitive
                personal information collected about them.
              </li>
            </li>
          </ol>
          <h3 className="mb-3 font-bold text-base">
            <b>Notice of Non-Discrimination</b>
          </h3>
          <p className="mb-6 font-medium text-gray-600">
            You have a right not to receive discriminatory treatment for exercising any of your CCPA privacy rights. We
            are committed to providing all consumers with equal services and prices, and We will not discriminate
            against you if you request to know, delete, opt-out, correct or limit.
          </p>
        </div>

        <h3 className="mb-3 font-bold text-lg">
          <b>Child Privacy</b>
        </h3>
        <p className="mb-6 font-medium text-gray-600">
          We do not knowingly collect any information from any individual under 13 years of age. If you are under 13
          years of age and using this website, please do not give Us any personal information and ask an adult for
          assistance if you need help.
        </p>

        <h3 className="mb-3 font-bold text-lg">
          <b>Contact Us</b>
        </h3>
        <p className="mb-4 font-medium text-gray-600">
          If you have any questions about this Privacy Policy, please contact Us at:{' '}
          <a href="mailto:support@helmsmartenergy.com" className={themeConfig.textColor}>
            support@helmsmartenergy.com
          </a>
        </p>
        <p className="mb-2 font-medium text-gray-600">You may also contact Our Privacy Officer by writing to:</p>
        <p className="font-medium text-gray-600">GreenMarbles, LLC</p>
        <p className="font-medium text-gray-600">Attn: Privacy Officer</p>
        <p className="font-medium text-gray-600">3419 Via Lido, Suite 388</p>
        <p className="font-medium text-gray-600">Newport Beach, CA 92663</p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
