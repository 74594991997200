import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import Carousel from '../Components/Carousel';
import Section4 from '../Components/Section4';
import Section6 from '../Components/Section6';
import Footer from '../Components/Footer';

import { ThemeContext } from '../App';
import HorizontalSplitBlock from '../Components/HorizontalSplitBlock';

export const About = () => {
  const themeConfig = useContext(ThemeContext);

  return (
    <>
      <Helmet>
        <title>Helm Smart Energy | About | Empowering Your EV Journey</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Navbar showlinks={true} />
      <Header
        title={themeConfig.headerContent.about.title}
        subtitle={themeConfig.headerContent.about.subtitle}
        ctaLink={themeConfig.headerContent.about.ctaLink}
        ctaText={themeConfig.headerContent.about.ctaText}
        bgClass={themeConfig.headerContent.about.bgClass}
      />
      <HorizontalSplitBlock
        imageURL={`${process.env.REACT_APP_IMGIX_URL}/helm/marketing-site/our_story.webp`}
        height={550}
        width={978}
        content={`<h1 class="text-[24px] font-bold text-black">Our Story</h1>
            <p class="text-lg leading-6 text-black max-w-md">
              When we saw the world of electric vehicles take off, we spotted a gap - folks needed help finding reliable
              electricians to set up their EV chargers. And that's where the idea for Helm Smart Energy was born. Why not
              make this easier and friendlier?
            </p>
            <p class="text-lg leading-6 text-black max-w-md">
              We've always put you, the customer, at the heart of our mission. We're all about turning the sometimes
              confusing journey of EV charger installation into a stress-free adventure. Today, we're proud to be the
              go-to for EV owners across the country, helping you go from our first hello, all the way to that
              satisfying moment when you start charging your EV at home.
            </p>`}
        wrapperMargin={`mt-28 mb-16`}
      />
      <Section2 />
      <Section4 />
      <Carousel heading={`See What Our Customers Have To Say`} showCta={false} />
      <Section6 />
      <Footer />
    </>
  );
};

const Section2 = () => {
  const themeConfig = useContext(ThemeContext);

  return (
    <div className="section-1 max-w-[320px] md:max-w-[1260px] mx-auto flex flex-col justify-center items-center mt-0 py-5 md:py-10">
      <h1 className="text-2xl lg:text-3xl text-center text-[#585858] font-bold">Our Core Values</h1>
      <p className="text-lg mx-auto max-w-xs md:max-w-xl lg:max-w-3xl text-center mt-12">
        These guiding principles represent the core beliefs that anchor our brand. We consider them our compass,
        directing every action we take and decision we make.
      </p>
      <div className="flex flex-col lg:flex-row justify-between w-full mx-auto mt-16 mb-10 relative">
        {/* item 1 */}
        <div className="flex flex-col lg:w-full justify-start items-center mx-5 mb-16 lg:mb-0">
          <div className="flex items-start justify-center w-full">
            <img
              src={`${process.env.REACT_APP_IMGIX_URL}/helm/marketing-site/values11.webp`}
              alt=""
              className={`border-[10px] ${themeConfig.borderColor} rounded-full`}
            />
          </div>
          <h1 className="text-[24px] font-medium text-[#363636] mt-6 mb-3">Do Good</h1>
          <p className="text-base text-center leading-6 font-medium text-[#727272] max-w-xs">
            Be compassionate, kind and charitable. You take on the day by asking yourself "what good shall I do this
            day?"
          </p>
        </div>

        {/* item 2 */}
        <div className="flex flex-col lg:w-full justify-start items-center mx-5 mb-16 lg:mb-0">
          <div className="flex items-start justify-center w-full">
            <img
              src={`${process.env.REACT_APP_IMGIX_URL}/helm/marketing-site/values12.webp`}
              alt=""
              className={`border-[10px] ${themeConfig.borderColor} rounded-full`}
            />
          </div>
          <h1 className="text-[24px] font-medium text-[#363636] mt-6 mb-3">Be A Champion</h1>
          <p className="text-base text-center leading-6 font-medium text-[#727272] max-w-xs">
            Integrity, responsibility, and take action - be the one to step forward and do what is right.
          </p>
        </div>

        {/* item 3 */}
        <div className="flex flex-col lg:w-full justify-start items-center mx-5 mb-16 lg:mb-0">
          <div className="flex items-start justify-center w-full">
            <img
              src={`${process.env.REACT_APP_IMGIX_URL}/helm/marketing-site/values33.webp`}
              alt=""
              className={`border-[10px] ${themeConfig.borderColor} rounded-full`}
            />
          </div>
          <h1 className="text-[24px] font-medium text-[#363636] mt-6 mb-3">Never Settle</h1>
          <p className="text-base text-center leading-6 font-medium text-[#727272] max-w-xs">
            Great things are not achieved by those who fear the challenge. Push your limits and produce change to reach
            the impossible.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
