/*
>> Theme constant containing theme specs
*/

export const helmTheme = {
  // colors
  borderColor: 'border-[#008b4f]',
  bgColor: 'bg-[#008b4f]',
  textColor: 'text-[#008b4f]',
  textHoverColor: 'hover:text-[#008b4f]',

  //constants
  logo: `${process.env.REACT_APP_IMGIX_URL}/helm/marketing-site/HelmSmartEnergy-Logo-Primary.webp`,
  calendlyURL: 'https://calendly.com/helm-smart-energy/ev-charger-installation-assessment',

  navContent: {
    ctaText: 'Schedule Your Consultation',
    ctaLink: '/schedule'
  },

  headerContent: {
    home: {
      title: `The Easy Button To Get Your EV Charger Installed`,
      subtitle: `Ready to get your EV Charger installed? You're in the right place. Let's start with a free consultation and estimate customized for your home.`,
      ctaLink: '/schedule',
      ctaText: `Schedule Your Consultation`,
      bgClass: `bg-home-header`
    },
    howItWorks: {
      title: `Ready, Set, Charge: How We Make EV Charging Easy`,
      subtitle: `We make the transition to electric driving seamless with our end-to-end charger installation process.`,
      ctaLink: '/schedule',
      ctaText: `Schedule Your Consultation`,
      bgClass: `bg-hiw-header`
    },
    about: {
      title: `Empowering Your EV Journey`,
      subtitle: `Learn about our mission to make EV charger installation simple and friendly.`,
      ctaLink: '/schedule',
      ctaText: `Schedule Your Consultation`,
      bgClass: `bg-about-header`
    }
  },

  stepsBlockContent: {
    pageHeading: 'What To Expect & How It Works',
    blocks: [
      {
        id: 1,
        title: `Remote Consultation`,
        desc: `Our team does a remote video consultation where you'll show us your electrical setup and desired charger location, assessing your specific needs and providing any guidance.`
      },
      {
        id: 2,
        title: `Receive A Quote`,
        desc: `We'll follow up with a detailed quote, explain permit requirements, and once the contract is signed, we'll schedule the installation with a certified electrician.`
      },
      {
        id: 3,
        title: `Installation`,
        desc: `We'll confirm the installation appointment, handle all permits, complete the installation, connect your charger to the Wi-Fi network (if applicable), and set up the final inspection.`
      },
      {
        id: 4,
        title: `Charge & Support`,
        desc: `With the installation complete, you're all set to start charging your EV and remember, our dedicated support line is always available for any questions or concerns.`
      }
    ],
    ctaText: `Schedule Your Consultation`,
    ctaLink: '/schedule'
  },

  howItWorksContent: {
    blocks: [
      {
        imageURL: `${process.env.REACT_APP_IMGIX_URL}/helm/marketing-site/rc.webp`,
        content: ` <h1 class="text-[24px] font-bold text-black">Remote Consultation</h1>
          <p class="text-lg leading-6 text-black max-w-md">
            The journey starts with a simple call from our care agents. We confirm your details and understand your
            vehicle and charger specifics.
          </p>
          <p class="text-lg leading-6 text-black max-w-md">
            Then, we arrange a video call where you'll show us your electrical panel, charger placement, and other
            important site details. These details help us get a clear idea of your specific installation
            requirements.
          </p>
          <p class="text-lg leading-6 text-black max-w-md">
            Based on this, we provide a detailed quote or schedule an in-person assessment within 48 hours, aiming
            for a seamless installation process.
          </p>`
      },
      {
        imageURL: `${process.env.REACT_APP_IMGIX_URL}/helm/marketing-site/quote.webp`,
        content: `<h1 class="text-[24px] font-bold text-black">Receive A Quote</h1>
          <p class="text-lg leading-6 text-black max-w-md">
            After the consultation, we prepare a detailed quote explaining everything from permits to installation.
            Our experts walk you through it all. And this quote is yours to consider for a full 30 days.
          </p>
          <p class="text-lg leading-6 text-black max-w-md">
            Once you're ready, we make the paperwork easy with a digital contract and schedule your installation
            with a certified electrician. We're with you every step of the way.
          </p>`
      },
      {
        imageURL: `${process.env.REACT_APP_IMGIX_URL}/helm/marketing-site/installation.webp`,
        content: `<h1 class="text-[24px] font-bold text-black">Installation</h1>
          <p class="text-lg leading-6 text-black max-w-md">
            Our electrician arrives on the scheduled day, armed with all necessary permits, to install your EV
            charger according to the agreed-upon plan.
          </p>
          <div>
            <h1 class="text-lg text-black max-w-md mb-1">Our service goes beyond just installation:</h1>
            <ul class="list-disc text-lg pl-8 leading-6 gap-y-2 flex flex-col">
              <li>We help connect your charger to your Wi-Fi network if applicable.</li>
              <li>We provide support with setting up the necessary mobile app if needed.</li>
              <li>We assist in locating any required model or serial numbers for future reference.</li>
            </ul>
          </div>
          <p class="text-lg leading-6 text-black max-w-md">
            After completing the installation and running all necessary tests, we schedule a final inspection to
            ensure everything is done right.
          </p>`
      },
      {
        imageURL: `${process.env.REACT_APP_IMGIX_URL}/helm/marketing-site/charge.webp`,
        content: `<h1 class="text-[24px] font-bold text-black">Charge & Support</h1>
          <p class="text-lg leading-6 text-black max-w-md">
            Once you approve the final inspection, your EV charging journey begins! We send the invoice via email,
            which you can pay using convenient digital payment methods.
          </p>
          <p class="text-lg leading-6 text-black max-w-md">
            Our service continues after the installation. Have questions about your new EV charger? We've got you
            covered. Our dedicated care agents are always just a phone call away, ready to assist.
          </p>
          <p class="text-lg leading-6 text-black max-w-md">
            At Helm Smart Energy, we're not just about setting you up with EV charging - we're your long-term
            partner, helping to make your electric driving experience smooth and enjoyable.
          </p>`
      }
    ]
  },

  preFooterContent: {
    title: 'Ready To Get Started?',
    desc: `Take the first step towards hassle-free EV charging at home by scheduling your remote consultation with
        Helm Smart Energy today.`,
    ctaText: `Schedule Your Consultation`,
    ctaLink: '/schedule'
  },

  footerContent: {
    footerLinks: [
      { ctaLink: '/about', ctaText: 'About' },
      { ctaLink: '/how-it-works', ctaText: 'How It Works' },
      { ctaLink: '/schedule', ctaText: 'Schedule Consultation' }
    ]
  },

  carouselContent: {
    ctaLink: '/schedule',
    ctaText: 'Get Started'
  },

  keyPointsContent: {
    bgClass: 'bg-section-4',
    pageHeading: 'What Makes Us Different',
    blocks: [
      {
        id: 1,
        title: `We're With You From Start To Finish`,
        desc: ` We're here to educate and walk you through every step of the process. Our ability to remotely consult
            with you and determine everything needed to install your EV charger saves you time. Most importantly, it
            ensures we can provide as much transparency as possible for your estimate, like changes in the
            electrical box or additional wiring.`
      },
      {
        id: 2,
        title: `We Back Up Every Installation`,
        desc: ` Our licensed electricians thoroughly inspect and test the charger's installation and operation. We
            leave the property only when you are fully confident in its functionality and are 100% satisfied.`
      },
      {
        id: 3,
        title: `Our White Glove Approach To Service`,
        desc: `Above all else, we pride ourselves in providing an outstanding experience to everyone we interact with. Taking away complexity and bringing satisfaction to your everyday life is a core tenet of
            our company. This approach runs through every aspect of our business, so we treat every interaction with
            the utmost care and respect.`
      }
    ]
  },

  scheduleContent: {
    showNavLinks: true,
    pageHeading: 'Schedule Your Free Consultation',
    calendlyContent: `<h1 class="font-bold leading-[36px] text-[24px] text-[#1C2839] max-w-sm"> Book Your Installation Consultation </h1> <div class="bg-[#008b4f] w-16 h-1 my-6"></div> <p class="text-sm text-[#272727] leading-6 mb-4 max-w-full lg:max-w-xs font-medium"> Great! Let's get you scheduled for a personalized installation assessment. </p> <p class="text-sm text-[#272727] leading-6 mb-4 max-w-full lg:max-w-xs font-medium"> Our virtual consultation will cover all the essentials to ensure your charger can be set up perfectly at your property. </p> <p class="text-sm text-[#272727] leading-6 max-w-full lg:max-w-xs font-medium"> Here's how to make the most of our chat: </p> <ul class="text-sm list-disc max-w-full lg:max-w-xs ml-3 font-medium"> <li class="my-2">Pick a spot for your charger? Let's see it during our call.</li> <li class="my-2">Have your phone or tablet handy.</li> <li class="my-2">Be ready to show us where your electrical panel is.</li> </ul> <p class="text-sm text-[#272727] leading-6 mt-3 max-w-full lg:max-w-xs font-semibold"> Installation starts at $1,299, and appointments are filling up fast - book your slot today! </p>`
  }
};

export default {
  helmTheme
};
